
import React from "react";
import AudioUpload from "../../../AudioUpload";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
const type = [
  "none",
  "text",
  "image",
  "audio"
]
const correctAnswerArray = [
  "option 1",
  "option 2",
  "option 3",
  "option 4"
]
const OptionView = ({
  type,
  Data,
  setData,
  placeholderValue,
  que
}) => {
  return (
    <div>
      {type == "text" &&
        <>
          {que ?
            <textarea rows={5}
              className="p-0 px-2 col-12"
              value={Data}
              onChange={(event) => { setData(event.target.value) }}
              placeholder={placeholderValue}
            />
            :
            <input
              className="p-0 px-2 col-12"
              value={Data}
              onChange={(event) => { setData(event.target.value) }}
              placeholder={placeholderValue}
            />
          }
        </>
      }
      {
        type == "image" &&
        <>
          <ImageUpload
            ImageUrl={Data}
            setImageUrl={setData}
          />
          <a style={{ color: "#000" }} href={Data} target="_blank">{Data}</a>
        </>
      }
      {
        type == "audio" &&
        <>
          <AudioUpload
            ImageUrl={Data}
            setImageUrl={setData}
          />
          <a style={{ color: "#000" }} href={Data} target="_blank">{Data}</a>
        </>
      }
    </div>
  )
}
export default function ExamQuestionModal(props) {
  const {
    oneQuestionData,
    keyValue,
    AllQuestionData,
    setAllQuestionData,
    PostExam,
    setEditQuestion
  } = props;

  const [question, setQuestion] = React.useState(oneQuestionData.question)
  const [questionDescription, setQuestionDescription] = React.useState(oneQuestionData.question_description)
  const [option1, setOption1] = React.useState(oneQuestionData.option_1)
  const [option2, setOption2] = React.useState(oneQuestionData.option_2)
  const [option3, setOption3] = React.useState(oneQuestionData.option_3)
  const [option4, setOption4] = React.useState(oneQuestionData.option_4)
  const [answerDescription, setAnswerDescription] = React.useState(oneQuestionData.answer_description)
  const [correctAnswer, setCorrectAnswer] = React.useState(oneQuestionData.correct_answer)
  const [questionMedia, setQuestionMedia] = React.useState(oneQuestionData.question_media)
  const [questionDescriptionType, setQuestionDescriptionType] = React.useState(oneQuestionData.question_description_type)
  const [questionMediaType, setQuestionMediaType] = React.useState(oneQuestionData.question_media_type)
  const [answerMediaType, setAnswerMediaType] = React.useState(oneQuestionData.answer_media_type)
  const [toastActive, setToastActive] = React.useState(false)
  const [toastText, setToastText] = React.useState("")
  
  return (
    <div>
      <table>
        <tr>
          <td className="col-4"><b>Question Number : </b></td>
          <td className="col-7">
            {/* <input
              className="p-0 px-2 col-12"
              type="text"
              value={question}
              onChange={(event) => { setQuestion(event.target.value) }}
              placeholder="Question"
              required
            /> */}
            {oneQuestionData.question_number}
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question : </b></td>
          <td className="col-7">
            <input
              className="p-0 px-2 col-12"
              value={question}
              onChange={(event) => { setQuestion(event.target.value) }}
              placeholder="Question"
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question Description: </b></td>
          <td className="col-7">
            <OptionView
              type={questionDescriptionType} que={true}
              Data={questionDescription}
              setData={setQuestionDescription}
              placeholderValue={"Question Description"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question Description Type: </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {type.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={item} />
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question Media </b></td>
          <td className="col-7">
            <OptionView
              type={questionMediaType} que={true}
              Data={questionMedia}
              setData={setQuestionMedia}
              placeholderValue={""}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question Media Type : </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {type.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  <CustomButton setData={setQuestionMediaType} Data={questionMediaType} DataValue={item} />
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Answer Type : </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {type.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  {item !== "none" &&
                    <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={item} />
                  }
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 1 : </b></td>
          <td className="col-7">
            <OptionView
              type={answerMediaType} que={false}
              Data={option1}
              setData={setOption1}
              placeholderValue={"Option 1"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 2 : </b></td>
          <td className="col-7">
            <OptionView
              type={answerMediaType} que={false}
              Data={option2}
              setData={setOption2}
              placeholderValue={"Option 2"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 3 : </b></td>
          <td className="col-7">
            <OptionView
              type={answerMediaType} que={false}
              Data={option3}
              setData={setOption3}
              placeholderValue={"Option 3"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 4 : </b></td>
          <td className="col-7">
            <OptionView
              type={answerMediaType} que={false}
              Data={option4}
              setData={setOption4}
              placeholderValue={"Option 4"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Correct Answer : </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {correctAnswerArray.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  {item !== "none" &&
                    <CustomButton setData={setCorrectAnswer} Data={correctAnswer} DataValue={item} />
                  }
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Answer Description : </b></td>
          <td className="col-7">
            <textarea cols={3}
              className="p-0 px-2 col-12"
              value={answerDescription}
              onChange={(event) => { setAnswerDescription(event.target.value) }}
              placeholder="Answer Description"
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <center>
              <div style={{ display: "inline" }}>
                <div style={{ width: "30%", display: "inline-block", }} className="sign-in-button-1"
                  onClick={() => {
                    var questionData = {
                      "question_number": oneQuestionData.question_number,
                      "question": question,
                      "question_description": questionDescription,
                      "question_description_type": questionDescriptionType,
                      "question_media": questionMedia,
                      "question_media_type": questionMediaType,
                      "option_1": option1,
                      "option_2": option2,
                      "option_3": option3,
                      "option_4": option4,
                      "answer_description": answerDescription,
                      "correct_answer": correctAnswer,
                      "answer_media_type": answerMediaType,
                    }
                    navigator.clipboard.writeText(JSON.stringify(questionData))
                    // setEditQuestion(false)
                    setToastText("Question Copied")
                    setToastActive(true)
                    setTimeout(() => {
                      setToastActive(false)
                    }, 1000)
                  }}
                >Copy Question
                </div>
                <div style={{ width: "30%" }} className="sign-in-button-1"
                  onClick={() => {
                    setAllQuestionData({
                      ...AllQuestionData,
                      [keyValue]: {
                        "question_number": oneQuestionData.question_number,
                        "question": question,
                        "question_description": questionDescription,
                        "question_description_type": questionDescriptionType,
                        "question_media": questionMedia,
                        "question_media_type": questionMediaType,
                        "option_1": option1,
                        "option_2": option2,
                        "option_3": option3,
                        "option_4": option4,
                        "answer_description": answerDescription,
                        "correct_answer": correctAnswer,
                        "answer_media_type": answerMediaType,
                      }
                    }
                    )
                    setEditQuestion(false)
                  }}
                >Done</div>

                <div style={{ width: "30%", display: "inline-block" }} className="sign-in-button-1"
                  onClick={async () => {
                    var pasteValue = await navigator.clipboard.readText()
                    if (pasteValue[0] == "{") {
                      console.log(JSON.parse(pasteValue))
                      var parseValue = JSON.parse(pasteValue)
                      setQuestion(parseValue.question)
                      setQuestionDescription(parseValue.question_description)
                      setQuestionDescriptionType(parseValue.question_description_type)
                      setQuestionMedia(parseValue.question_media)
                      setQuestionMediaType(parseValue.question_media_type)
                      setOption1(parseValue.option_1)
                      setOption2(parseValue.option_2)
                      setOption3(parseValue.option_3)
                      setOption4(parseValue.option_4)
                      setAnswerDescription(parseValue.answer_description)
                      setAnswerMediaType(parseValue.answer_media_type)
                      setCorrectAnswer(parseValue.correct_answer)
                      setToastText("Question Pasted")
                    }
                    // navigator.clipboard.writeText(JSON.stringify(question))
                    // setEditQuestion(false)
                  }}
                >Paste Question</div>
              </div>
            </center>
          </td>
        </tr>
      </table>
    </div>
  )
}
